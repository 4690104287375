
.createSubBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #000;
}

.createSubFormBody {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*justify-content: space-between;*/
    font-size: calc(10px + 2vmin);
    max-width: 80%;
    margin: 0 auto;
}