.userDetailsHeader {
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
    /*margin-bottom: 1rem;*/
    color: black;
}

.userDetailsBody {
    background-color: lightgray;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
}

.userDetailsList {
    background-color: lightgray;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
}