.header {
    background-color: #5F9EA0; /* vibrant purple */
    /* other common styles for header and footer */
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.footer {
    background-color: #5F9EA0; /* vibrant purple */
    margin-top: 2vh;  /* top margin */
    margin-bottom: 2vh; /* bottom margin */
    padding-bottom: 2vh; /* bottom padding */
    color: #ffffff;
}

.showOnMobile {
    display: none;
}

.ngigsLogo {
    margin-bottom: 1rem;
    color: #ffffff;
}

.vercelLogo {
    /*filter: invert(1);*/
}

.titleCase {
    text-transform: capitalize;
}

.copyright {
    margin-top: 0.5rem;    /* Adjust space above the copyright text */
    margin-bottom: 50px !important;
    font-size: 0.9rem;     /* Adjust the font size */
    color: #ffffff;        /* Adjust the color */
    text-align: center;    /* Center-align the text */
    width: 100%;           /* Make it span the entire width of the parent element */
}

.whiteText {
    color: #ffffff;
}

/* phone size devices */
@media (max-width: 414px) {
    .header {
        display: flex;
        flex-direction: column;
        background-color: #5F9EA0;
        /* other styles as needed */
        /*overflow-y: auto;*/
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .footer {
        display: flex;
        flex-direction: column;
        background-color: #5F9EA0; /* vibrant purple */
        margin-top: 2vh;  /* top margin */
        margin-bottom: 2vh; /* bottom margin */
        padding-bottom: 2vh; /* bottom padding */
    }

    .whiteText {
        color: #ffffff;
    }
}
